import { AnalyticsBrowser, EventProperties, ID } from '@segment/analytics-next';

const APPLICATION = 'admin';

export const analyticsBrowser = new AnalyticsBrowser();

// Extract the types of the parameters of `analytics.track`
type AnalyticsTrackEventParams = Parameters<typeof analyticsBrowser.track>;

// Extract the types of the parameters of `analytics.page`
type AnalyticsPageEventParams = Parameters<typeof analyticsBrowser.page>;

const identifyWithUserMetadata = (
  id: ID,
  user: { email?: string; firstName?: string; lastName?: string },
) => {
  analyticsBrowser.identify(id, {
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
  });
};

// Since "window" and "screen" needs to be accessed in the browser, we can't move this outside of the function
const getCommonMetadata = (): EventProperties => ({
  width: window.innerWidth,
  height: window.innerHeight,
  screen_width: screen.width,
  screen_height: screen.height,
  application: APPLICATION,
});

const trackEventWithMetadata = (...args: AnalyticsTrackEventParams) => {
  const [eventName, properties, ...rest] = args;

  analyticsBrowser.track(eventName, { ...properties, ...getCommonMetadata() }, ...rest);
};

const trackPageEventWithMetadata = (...args: AnalyticsPageEventParams) => {
  const [category, name, properties, ...rest] = args;

  analyticsBrowser.page(category, name, { ...properties, ...getCommonMetadata() }, ...rest);
};

// Export the analytics object with the track and page methods
export const analytics = {
  identify: identifyWithUserMetadata,
  page: trackPageEventWithMetadata,
  track: trackEventWithMetadata,
};
